import React, { useEffect, useState } from 'react';
// import RepliesPageModel from "./../ItemsPageModel/RepliesPageModel";
import axios from 'axios';
import backendApi from '../../../utility/backendApi';
import { useDidUpdate } from '@mantine/hooks';
import { useQuery, useQueryClient } from 'react-query';
import useUser from './../../../react-query-hooks/useUser/useUser';
import RepliesPageModel from '../ItemsPageModel/RepliesPageModel';

export default function MyPostReplies() {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const limit = 20;

  const fetchPostReplies = (page = 1) => {
    return axios
      .get(`${backendApi}users/getMyPostReplies?&limit=${limit}&page=` + page, {
        withCredentials: true
      })
      .then(res => res.data);
  };

  const [page, setPage] = useState(
    sessionStorage.getItem(`myPostReplies-page`) * 1 || 1
  );

  useDidUpdate(() => {
    sessionStorage.setItem(`myPostReplies-page`, page);
    // window.location.reload();
  }, [page]);

  const { data, isFetching } = useQuery(
    ['myPostReplies', { page }],
    () => fetchPostReplies(page),
    { keepPreviousData: true, staleTime: 1000 }
  );

  const total = Math.ceil(data?.totalDocsInDB / limit);

  // Prefetch the next page!
  useEffect(() => {
    if (data?.totalDocsInDB > page * limit) {
      queryClient.prefetchQuery(['myPostReplies', { page: page + 1 }], () =>
        fetchPostReplies(page + 1)
      );
    }
  }, [data, page, queryClient]);

  return (
    <>
      <RepliesPageModel
        title="My post-replies"
        isLoading={isFetching}
        replies={data?.data}
        total={total}
        setPage={setPage}
        page={page}
        user={user}
      />
    </>
  );
}
